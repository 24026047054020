<template>
  
  <div v-if="User != null" class="mx-0 lg:mb-6 md:mb-6 p-4">
    <div class="flex sm:flex-row flex-col items-start">

      <div class="mr-6 mb-6 w-full sm:w-96">

        <form @submit.prevent="setImage">

        <div :class="(dark && User.role =='etudiant') ? 'bg-gray-700 border-gray-700 text-white' : 'bg-white'" class="container flex flex-col mx-auto p-9 max-w-sm rounded-lg overflow-hidden border hover:shadow-2xl transition duration-300">
          <img class="rounded-lg" :src="imageUrl" alt="" />
          <div class="flex flex-col items-center">
            <div class="text-center">
              <h1 class="mt-5 text-2xl font-semibold">{{User.lastname}} {{User.firstname}}</h1>
              <p class="my-2">{{ User.email }}</p>
            </div>


            <div class="mt-4">
              <label v-if="imageFile === ''" class="py-2 px-4 mt-4 text-white font-semibold bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">   
                <span>Modifier</span>
                <input type="file" accept="image/*" name="image" @change="getImage" hidden />
              </label>
              
              <div class="flex flex-col justify-center" v-else>
                <button
                  type="submit"
                  class="py-2 px-4 mb-2 text-white font-semibold bg-red-400 rounded-lg hover:shadow-lg">
                    Confirmer
                </button>
                <button  class="py-2 px-6 text-gray-700 font-semibold bg-gray-200 rounded-lg hover:shadow-lg"
                  type="button"
                  @click="cancel"
                >
                  Annuler
                </button>
              </div>

            </div>
            <div class="mt-4" v-if="isImage !== null "> 
              <button v-if="imageFile === ''" class="py-2 px-6 text-gray-700 font-semibold bg-gray-200 rounded-lg hover:shadow-lg"
                  type="button"
                  @click="deleteImageUser(User.id)"
                >
                  Supprimer
                </button>
            </div>
          </div>
        </div>

        </form>

      </div>


      <div :class="(dark && User.role =='etudiant') ? 'bg-gray-700 border-gray-700' : 'bg-white'" class="border rounded-lg w-full hover:shadow-2xl transition duration-300">
        <div class="top p-4  select-none border-b ">
          <div class="ml-auto flex mr-1">
            <button class="flex flex-row items-center mx-2"  :class=" slider == 'informations' ? 'text-red-400 ' : (dark && User.role =='etudiant') ? 'text-white' : 'text-gray-700'">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
              <span class="mx-2" @click="slider = 'informations'">informations personnelles</span>
            </button>
            <button class="flex flex-row items-center mx-2" :class=" slider == 'password' ? 'text-red-400 ' : (dark && User.role =='etudiant') ? 'text-white' : 'text-gray-700'">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
              <span class="mx-2" @click="slider = 'password'">mot de passe</span>
            </button>
          </div>
        </div>


        <div class="w-full sm:h-96 h-auto ">

          <div v-show="slider=='informations'" class="m-4 flex flex-col justify-between p-8">
            <div class=" flex flex-col sm:flex-row items-center mb-5 sm:space-x-5">
              <div :class="(dark && User.role =='etudiant') ? 'text-white':'text-gray-700'" class="w-full sm:w-1/3">
                <p class="sm:ml-0 ml-2 text-sm font-semibold">
                  Nom de famille
                </p>
              </div>
              <div class="w-full sm:w-2/3 mt-2 sm:mt-0">
                <el-input
                    v-model="form.lastname" placeholder="Nom de famille"
                  />
              </div>
            </div> 

            <div class=" flex flex-col sm:flex-row items-center mb-5 sm:space-x-5">
              <div  :class="(dark && User.role =='etudiant') ? 'text-white':'text-gray-700'" class="w-full sm:w-1/3">
                <p class="sm:ml-0 ml-2 text-sm font-semibold">
                  Prénom
                </p>
              </div>
              <div class="w-full sm:w-2/3 mt-2 sm:mt-0">
                <el-input
                    v-model="form.firstname" placeholder="Prénom"
                  />
              </div>
            </div>

            <div v-if="isUser.role == 'etudiant'" class=" flex flex-col sm:flex-row items-center mb-5 sm:space-x-5" >
              <div  :class="(dark && User.role =='etudiant') ? 'text-white':'text-gray-700'" class="w-full sm:w-1/3">
                <p class="sm:ml-0 ml-2 text-sm font-semibold ">
                  Université
                </p>
              </div>
              <div class="w-full sm:w-2/3 mt-2 sm:mt-0">
                <el-select class="w-full"
                  v-model="form.university"
                  placeholder="Université d'étude">
                  <el-option
                    v-for="item in facultes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>


            <div v-if="isUser.role == 'etudiant' " class=" flex flex-col sm:flex-row items-center mb-5 sm:space-x-5" >
              <div  :class="(dark && User.role =='etudiant') ? 'text-white':'text-gray-700'" class="w-full sm:w-1/3">
                <p class="sm:ml-0 ml-2 text-sm font-semibold">
                  Niveau d'étude
                </p>
              </div>
              <div class="w-full sm:w-2/3 mt-2 sm:mt-0">
                <el-select class="w-full"
                  v-model="form.year"
                  placeholder="Niveau d'études"
                >
                  <el-option
                    v-for="item in years"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="w-full mt-3 mb-4 text-center">
              <button  @click="updateUserData(User.id)" class="px-8 py-2 text-white font-semibold bg-red-400 rounded-lg">
                Modifier
              </button>
            </div>

          </div>


          <div v-show="slider=='password'" class="m-4 flex flex-col justify-between p-8">
            <div class=" flex flex-col sm:flex-row items-center mb-5 sm:space-x-5">
              <div  :class="(dark && User.role =='etudiant') ? 'text-white':'text-gray-700'" class="w-full sm:w-1/3">
                <p class="sm:ml-0 ml-2 text-sm font-semibold">
                  Ancien mot de passe
                </p>
              </div>
              <div class="w-full sm:w-2/3 mt-2 sm:mt-0">
                <el-input
                    v-model="passForm.old_password"
                    placeholder="Veuillez introduire votre ancien mot de passe"
                    show-password
                  />
              </div>
            </div> 

            <div class=" flex flex-col sm:flex-row items-center mb-5 sm:space-x-5">
              <div  :class="(dark && User.role =='etudiant') ? 'text-white':'text-gray-700'" class="w-full sm:w-1/3">
                <p class="sm:ml-0 ml-2 text-sm font-semibold ">
                  Mot de passe
                </p>
              </div>
              <div class="w-full sm:w-2/3 mt-2 sm:mt-0">
                <el-input
                    v-model="passForm.new_password"
                    placeholder="Veuillez introduire votre nouveau mot de passe"
                    show-password
                  />
              </div>
            </div> 

            <div class=" flex flex-col sm:flex-row items-center mb-5 sm:space-x-5">
              <div  :class="(dark && User.role =='etudiant') ? 'text-white':'text-gray-700'" class="w-full sm:w-1/3">
                <p class="sm:ml-0 ml-2 text-sm font-semibold">
                  Confirmer mot de passe
                </p>
              </div>
              <div class="w-full sm:w-2/3 mt-2 sm:mt-0">
                <el-input
                    v-model="passForm.new_password_confirmation"
                    placeholder="Veuillez confirmer votre ancien mot de passe"
                    show-password
                  />
              </div>
            </div> 

            <div class="w-full mt-3 mb-4 text-center">
              <button @click="updatePassword(User.id)" class="px-8 py-2 text-white font-semibold bg-red-400 rounded-lg">
                Modifier
              </button>
            </div>

          </div>
          
        </div>




      </div>

    </div>

    
  </div>
</template>

<script>

import store from "../../store.js";
import { useImageUpload } from "@/composables/useImageUpload.js";
import useEmitter from "@/composables/useEmitter";
import { ElMessage } from 'element-plus';
import axiosIns from "../../plugins/axios.js";


export default {

  data() {
    return {
      currentRoute: window.location.pathname,
      form: {
        firstname: "",
        lastname: "",
        university: "",
        year: "",
      },
      passForm:{
        old_password: "",
        new_password: "",
        new_password_confirmation: ""
      },

      User: [],
      error: null,
      isLoading: false,
      avatar: null,
      slider: 'informations',

      facultes: [
        {
          value: 'Alger',
          label: "Faculté d'Alger",
        },
        {
          value: 'Constantine',
          label: 'Faculté de Constantine',
        },
        {
          value: 'Annaba',
          label: "Faculté d'Annaba",
        },
        {
          value: 'Sétif',
          label: 'Faculté de Sétif',
        },
        {
          value: 'Batna',
          label: 'Faculté de Batna',
        },
        {
          value: 'Bejaia',
          label: 'Faculté de Bejaia',
        },
        {
          value: 'Laghouat',
          label: 'Faculté de Laghouat',
        },
        {
          value: 'Mostaganem',
          label: 'Faculté de Mostaganem',
        },
        {
          value: 'Oran',
          label: "Faculté d'Oran",
        },
        {
          value: 'Ouargla',
          label: "Faculté d'Ouargla",
        },
        {
          value: 'Blida',
          label: "Faculté de Blida",
        },
        {
          value: 'Tizi Ouzou',
          label: "Faculté de Tizi Ouzou",
        },
        {
          value: 'Tlemcen',
          label: "Faculté de Tlemcen",
        },
        {
          value: 'Sidi Bel Abbès',
          label: "Faculté de Sidi Bel Abbès",
        },
        {
          value: 'Béchar',
          label: "Faculté de Béchar",
        },
      ],
      years: [
        {
          value: '1',
          label: '1 Année',
        },
        {
          value: '2',
          label: '2 Année',
        },
        {
          value: '3',
          label: '3 Année',
        },
        {
          value: '4',
          label: '4 Année',
        },
        {
          value: '5',
          label: '5 Année',
        },
        {
          value: '6',
          label: '6 Année',
        },
      ],
    };
  },

  props:{
    linkPath:Function,
    dark: Boolean
  },


  computed: {
    isToken() {
      return this.$store.getters.get_token;
    },
    isUser() {
      return this.$store.getters.get_user;
    },
    isImage(){
      return this.$store.getters.get_image;
    }
  },

  mounted() {
    this.linkPath(this.currentRoute)

    this.isLoading = true;
    if (this.$store.getters.get_user) {
      this.User = this.$store.getters.get_user;

      this.form.firstname = this.User.firstname;
      this.form.lastname = this.User.lastname;
      this.form.university = this.User.university;
      this.form.year = this.User.year;

      this.isLoading = false;
    } else {
      this.isLoading = false;
      // this.error = error.response.data.error;
    }
  },
  methods: {
    
    read(image) {
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.avatar = e.target.result;
      };
    },

    updateUserData(id){
      axiosIns.put(
          "/auth/userUpdate/" +id,
            this.form
        )
        .then((data) => {
          this.succesUpdate()
          this.User = data.data;
          store.commit("set_user",data.data);
          this.eventBus.emit("EventUser", data.data);
        })
        .catch(() => {
          this.errorm();
          this.error = "Erreur de modification du profil";
        });
    },


    updatePassword(id){
      axiosIns.put(
          "/auth/passwordUpdate/" +id,
            this.passForm
        )
        .then(() => {
          this.succesUpdate()
          this.$store.dispatch("logoutAction").then(() => {
            this.$router.push("/login");
            this.$store.commit('set_user', null);
          });
        })
        .catch(() => {
          this.errorm();
          this.error = "Erreur de modification du profil";
        });
    },

    deleteImageUser(id){
      axiosIns.put(
          "/auth/imageDelete/" +id
        )
        .then(() => {
          this.succesUpdate()
          this.imageUrl = "/avatar_profile.jpg"
          this.eventBus.emit("EventPhoto","/avatar_profile.jpg" );
          this.$store.commit("set_image", null);
        })
        .catch(() => {
          this.errorm();
          this.error = "Erreur de modification du profil";
        });

    }



  },
  setup() {
    let { getImage, imageUrl, imageFile, cancel } = useImageUpload();
    const eventBus = useEmitter();
    function setImage() {
      let form = new FormData();
      form.append("profil_image", imageFile.value);
      axiosIns.post(
          "/auth/setImage/" +
            store.getters.get_user.id,
          form
        )
        .then((data) => {
          store.commit("set_image", data.data.profil_image);
          eventBus.emit("EventPhoto", data.data.profil_image);
          cancel();
        })
        .catch(() => {
          this.errorm();
          this.error = "Erreur d'ajout d'image";
        });
    }

    const succesUpdate = () => {
      ElMessage({
        message: "Votre profile a été modifié avec succès.",
        type: 'success',
      })
    }
    const errorm = () => {
      ElMessage.error('Une erreur est survenue !')
    }

    return { getImage, imageUrl, imageFile, cancel, setImage,errorm,succesUpdate };
  },


  
};
</script>

<style>


</style>